import Layout from '@/layout'

function addRouteFun(routerObj) {
  // 权限
  let obj = {
    path: '/administrators',
    component: Layout,
    name: 'administrators',
    children: [
      {
        path: 'page',
        name: 'administratorsPage',
        component: () => import('@/views/administrators/Administrators'),
        meta: { title: '管理员', icon: 'el-icon-s-grid' }
      }
    ]
  }
  let typeData = localStorage.getItem('roles')

  if (typeData === 'true') {
    // console.log('添加路由')
    routerObj.addRoute('/', obj)
  }
  // 账号jw**
  let luocha = {
    path: '/luocha',
    component: Layout,
    name: 'luocha',
    children: [
      {
        path: 'data',
        name: 'luochaData',
        component: () => import('@/views/luocha/Luocha'),
        meta: { title: '落查', icon: 'el-icon-user-solid' }
      }
    ]
  }

  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (userInfo && userInfo.username.indexOf('JW58') == -1) {
    routerObj.addRoute('/', luocha)
  }
}
export default addRouteFun
