import Layout from '@/layout'
export let luocha = {
  path: '/luocha',
  component: Layout,
  name: 'luocha',
  children: [
    {
      path: 'data',
      name: 'luochaData',
      component: () => import('@/views/luocha/Luocha'),
      meta: { title: '落查', icon: 'el-icon-user-solid' }
    }
  ]
}
