import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import addRouteFun from './administrators'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/management',
    component: Layout,
    name: 'management',
    meta: {
      title: '方案管理',
      icon: 'el-icon-s-platform'
    },
    children: [
      {
        path: 'user',
        name: 'managementUser',
        component: () => import('@/views/management/User.vue'),
        meta: {
          title: '用户方案',
          icon: 'el-icon-user-solid'
        }
      },
      {
        path: 'keywords',
        name: 'managementKeyWords',
        component: () => import('@/views/management/Keywords.vue'),
        meta: {
          title: '关键词方案',
          icon: 'el-icon-edit'
        }
      }
    ]
  },
  {
    path: '/data',
    component: Layout,
    name: 'data',
    meta: {
      title: '数据检索',
      icon: 'el-icon-message'
    },
    children: [
      {
        path: 'user',
        name: 'dataUser',
        component: () => import('@/views/user/Data'),
        meta: { title: '用户数据', icon: 'el-icon-user' }
      },
      {
        path: 'posts',
        name: 'dataPosts',
        component: () => import('@/views/user/Posts'),
        meta: { title: '帖子数据', icon: 'el-icon-postcard' }
      }
    ]
  },

  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/errorPage/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
addRouteFun(router)

export default router
