import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=7f0a276e&scoped=true"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=7f0a276e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f0a276e",
  null
  
)

export default component.exports