<template>
  <div class="no-data" :style="{ 'font-size': fontSize, color: color }">
    {{ text }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    fontSize: {
      type: String,
      default: '20px'
    },
    color: {
      type: String,
      default: '#8b8b8b'
    },
    text: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  position: 99999;
}
</style>
